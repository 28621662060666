import React from "react";

import Head from "../components/head";
import Layout from "../components/layout";
import supportStyles from "./support.module.scss";

const Support = () => {
  return (
    <Layout>
      <Head title="Support" />
      <article className={supportStyles.content}>
        <h2>Contact Us</h2>
        <p>
          If you have an questions or concerns, please feel free to reach out to
          us at <a href="mailto:smads_robots@utexas.edu">smads_robots@utexas.edu</a>.
        </p>
      </article>
    </Layout>
  );
};

export default Support;
